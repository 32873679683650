import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Role } from '../models/index';
import Authenticate from '../components/Identity/Authenticate';
import '../styles/Index.module.scss';

const IndexPage = (props) => {
  const { loggedInUserDatastoreData } = props;

  useEffect(() => {
    if (loggedInUserDatastoreData) {
      if (loggedInUserDatastoreData?.roles?.includes(Role.ADMIN)
      || loggedInUserDatastoreData?.roles?.includes(Role.MANAGER)
      || loggedInUserDatastoreData?.roles?.includes(Role.OWNER)) {
        navigate('/admin/overview/', { replace: true });
      } else {
        navigate('/learner/', { replace: true });
      }
    }
  }, [loggedInUserDatastoreData]);

  return (<></>);
};
IndexPage.propTypes = {
  loggedInUserDatastoreData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Authenticate(IndexPage);
